<template>
	<div class="container-fluid py-3">
		<div class="row">
			<div class="col-4 col-sm-3 col-md-2 mb-3">
				<router-link
					:to="{ name: 'Premium', params: { lang: lang, console: console } }"
				>
					<div class="card">
						<img
							class="card-img-top fluid"
							src="../../assets/images/premium.png"
							alt="Premiun"
						/>
						<div class="card-body">
							<h6 class="h6 text-center py-2 mb-0">PREMIUM</h6>
						</div>
					</div>
				</router-link>
			</div>
			<div class="col-4 col-sm-3 col-md-2 mb-3">
				<router-link
					:to="{ name: 'Coins', params: { lang: lang, console: console } }"
				>
					<div class="card">
						<img
							class="card-img-top fluid"
							src="../../assets/images/money.png"
							alt="Monedas"
						/>
						<div class="card-body">
							<h6 class="h6 text-center py-2 mb-0">{{ $t('coins') }}</h6>
						</div>
					</div>
				</router-link>
			</div>
			<div class="col-4 col-sm-3 col-md-2 mb-3">
				<router-link
					:to="{ name: 'Vs', params: { lang: lang, console: console } }"
				>
					<div class="card">
						<img
							class="card-img-top fluid"
							src="../../assets/images/money.png"
							alt="Monedas"
						/>
						<div class="card-body">
							<h6 class="h6 text-center py-2 mb-0">VS</h6>
						</div>
					</div>
				</router-link>
			</div>
			<div
				class="col-4 col-sm-3 col-md-2 mb-3"
				v-for="category in categories"
				:key="category.id"
			>
				<div class="card">
					<router-link
						:to="{
							name: 'ShopCategory',
							params: { lang: lang, console: console, slug: category.slug },
						}"
					>
						<category-section :category="category" />
					</router-link>
					<div
						class="card-footer"
						v-if="isAuthenticated && user.type === 'SUPER'"
					>
						<router-link
							:to="{
								name: 'ShopCategoryEdit',
								params: {
									lang: lang,
									console: console,
									categoryId: category.id,
								},
							}"
							class="btn btn-success"
						>
							<i class="fa fa-pencil px-1"></i>
						</router-link>
						<b-dropdown right class="float-right" no-caret>
							<div slot="button-content">
								<i class="fa fa-trash px-1"></i>
							</div>
							<b-dropdown-form class="py-3">
								<b-dropdown-text class="font-weight-bold p-0">{{
									$t('confirmTextDelete')
								}}</b-dropdown-text>
								<div class="w-100 mt-3">
									<b-button @click="closeAllDD()" variant="primary">{{
										$t('not')
									}}</b-button>
									<b-button
										@click="deleteCategory(category.id)"
										variant="danger"
										class="float-right px-3"
										>{{ $t('yes') }}</b-button
									>
								</div>
							</b-dropdown-form>
						</b-dropdown>
					</div>
				</div>
			</div>
			<!-- Crear categoría -->
			<div
				class="col-4 col-sm-3 col-md-2 mb-3"
				v-if="isAuthenticated && user.type === 'SUPER'"
			>
				<router-link
					:to="{
						name: 'ShopCategoryCreate',
						params: { lang: lang, console: console },
					}"
				>
					<div class="card">
						<img
							class="card-img-top fluid"
							src="../../assets/images/add_button.png"
							alt="Agregar categoría"
						/>
						<div class="card-body">
							<h6 class="h6 text-center py-2 mb-0">{{ $t('addCategory') }}</h6>
						</div>
					</div>
				</router-link>
			</div>
			<!-- Crear productos -->
			<div class="col-4 col-sm-3 col-md-2 mb-3" v-if="user.type === 'SUPER'">
				<router-link
					:to="{
						name: 'ProductCreate',
						params: { lang: lang, console: console },
					}"
				>
					<div class="card">
						<img
							class="card-img-top fluid"
							src="../../assets/images/add_button.png"
							alt="Agregar categoría"
						/>
						<div class="card-body">
							<h6 class="h6 text-center py-2 mb-0">
								{{ $t('createProduct') }}
							</h6>
						</div>
					</div>
				</router-link>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<hr />
			</div>
		</div>
		<products-section :is-owner="isOwner" />
		<b-pagination
			align="center"
			v-model="currentPage"
			:total-rows="rows"
			:per-page="perPage"
		></b-pagination>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import ProductsSection from './partials/Product'
import CategorySection from './partials/Category'

export default {
	components: {
		ProductsSection,
		CategorySection,
	},
	data() {
		return {
			categories: [],
			isOwner: false,
			path: '',
			currentPage: 1,
			rows: 1,
			perPage: 1,
		}
	},
	computed: {
		...mapGetters(['console', 'lang', 'user', 'isAuthenticated', 'products']),
	},
	watch: {
		currentPage(currentPage) {
			this.$router.push({
				name: 'Shop',
				params: { lang: this.lang, console: this.console },
				query: { page: currentPage },
			})
			this.fetchData()
		},
	},
	mounted() {
		this.path = `${this.lang}/console/${this.console}/shop/home`
		this.fetchData()
	},
	methods: {
		deleteCategory(categoryId) {
			const path = `auth/${this.lang}/console/${
				this.console
			}/shop/categories/${categoryId}/delete`
			this.$axios.delete(path).then((response) => {
				this.$toastr.success(response.data.message, window.TITLE_SUCCESS)
				this.categories = this.categories.filter((cat) => {
					return cat.id !== categoryId
				})
			})
		},
		setPage() {
			const page = this.$route.query.page
			if (page) {
				return page
			} else {
				return 1
			}
		},
		fetchData() {
			const params = {
				page: this.setPage(),
			}
			this.$axios.get(this.path, { params }).then((response) => {
				const data = response.data
				this.categories = data.categories
				this.$store.dispatch('SET_PRODUCTS', data.products)
				this.isOwner = data.owner
				this.rows = this.products.total
				this.perPage = this.products.per_page
			})
		},
	},
}
</script>
