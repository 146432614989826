var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid py-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4 col-sm-3 col-md-2 mb-3"},[_c('router-link',{attrs:{"to":{ name: 'Premium', params: { lang: _vm.lang, console: _vm.console } }}},[_c('div',{staticClass:"card"},[_c('img',{staticClass:"card-img-top fluid",attrs:{"src":require("../../assets/images/premium.png"),"alt":"Premiun"}}),_c('div',{staticClass:"card-body"},[_c('h6',{staticClass:"h6 text-center py-2 mb-0"},[_vm._v("PREMIUM")])])])])],1),_c('div',{staticClass:"col-4 col-sm-3 col-md-2 mb-3"},[_c('router-link',{attrs:{"to":{ name: 'Coins', params: { lang: _vm.lang, console: _vm.console } }}},[_c('div',{staticClass:"card"},[_c('img',{staticClass:"card-img-top fluid",attrs:{"src":require("../../assets/images/money.png"),"alt":"Monedas"}}),_c('div',{staticClass:"card-body"},[_c('h6',{staticClass:"h6 text-center py-2 mb-0"},[_vm._v(_vm._s(_vm.$t('coins')))])])])])],1),_c('div',{staticClass:"col-4 col-sm-3 col-md-2 mb-3"},[_c('router-link',{attrs:{"to":{ name: 'Vs', params: { lang: _vm.lang, console: _vm.console } }}},[_c('div',{staticClass:"card"},[_c('img',{staticClass:"card-img-top fluid",attrs:{"src":require("../../assets/images/money.png"),"alt":"Monedas"}}),_c('div',{staticClass:"card-body"},[_c('h6',{staticClass:"h6 text-center py-2 mb-0"},[_vm._v("VS")])])])])],1),_vm._l((_vm.categories),function(category){return _c('div',{key:category.id,staticClass:"col-4 col-sm-3 col-md-2 mb-3"},[_c('div',{staticClass:"card"},[_c('router-link',{attrs:{"to":{
						name: 'ShopCategory',
						params: { lang: _vm.lang, console: _vm.console, slug: category.slug },
					}}},[_c('category-section',{attrs:{"category":category}})],1),(_vm.isAuthenticated && _vm.user.type === 'SUPER')?_c('div',{staticClass:"card-footer"},[_c('router-link',{staticClass:"btn btn-success",attrs:{"to":{
							name: 'ShopCategoryEdit',
							params: {
								lang: _vm.lang,
								console: _vm.console,
								categoryId: category.id,
							},
						}}},[_c('i',{staticClass:"fa fa-pencil px-1"})]),_c('b-dropdown',{staticClass:"float-right",attrs:{"right":"","no-caret":""}},[_c('div',{attrs:{"slot":"button-content"},slot:"button-content"},[_c('i',{staticClass:"fa fa-trash px-1"})]),_c('b-dropdown-form',{staticClass:"py-3"},[_c('b-dropdown-text',{staticClass:"font-weight-bold p-0"},[_vm._v(_vm._s(_vm.$t('confirmTextDelete')))]),_c('div',{staticClass:"w-100 mt-3"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.closeAllDD()}}},[_vm._v(_vm._s(_vm.$t('not')))]),_c('b-button',{staticClass:"float-right px-3",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.deleteCategory(category.id)}}},[_vm._v(_vm._s(_vm.$t('yes')))])],1)],1)],1)],1):_vm._e()],1)])}),(_vm.isAuthenticated && _vm.user.type === 'SUPER')?_c('div',{staticClass:"col-4 col-sm-3 col-md-2 mb-3"},[_c('router-link',{attrs:{"to":{
					name: 'ShopCategoryCreate',
					params: { lang: _vm.lang, console: _vm.console },
				}}},[_c('div',{staticClass:"card"},[_c('img',{staticClass:"card-img-top fluid",attrs:{"src":require("../../assets/images/add_button.png"),"alt":"Agregar categoría"}}),_c('div',{staticClass:"card-body"},[_c('h6',{staticClass:"h6 text-center py-2 mb-0"},[_vm._v(_vm._s(_vm.$t('addCategory')))])])])])],1):_vm._e(),(_vm.user.type === 'SUPER')?_c('div',{staticClass:"col-4 col-sm-3 col-md-2 mb-3"},[_c('router-link',{attrs:{"to":{
					name: 'ProductCreate',
					params: { lang: _vm.lang, console: _vm.console },
				}}},[_c('div',{staticClass:"card"},[_c('img',{staticClass:"card-img-top fluid",attrs:{"src":require("../../assets/images/add_button.png"),"alt":"Agregar categoría"}}),_c('div',{staticClass:"card-body"},[_c('h6',{staticClass:"h6 text-center py-2 mb-0"},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_vm.$t('createProduct'))+"\n\t\t\t\t\t\t")])])])])],1):_vm._e()],2),_vm._m(0),_c('products-section',{attrs:{"is-owner":_vm.isOwner}}),_c('b-pagination',{attrs:{"align":"center","total-rows":_vm.rows,"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('hr')])])
}]

export { render, staticRenderFns }